<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="error"
        class="text-pre-wrap"
        v-if="alertDanger.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertDanger"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="refundEmailSubmissionSubmitDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          {{ this.model.name.singular }}
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to Draft this Refund Email for next
          approval.
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRefundEmailSubmissionSubmit()"
          >
            Draft
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="refundEmailSubmissionSubmitDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="isTaxpodAdminRoles && !btnDisabled && !editMode">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="refundEmailSubmissionSubmitDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Submit Refund Email Draft</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div>
        <v-row
          class="d-flex justify-space-between"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <!-- Claim Amount -->
          <v-col cols="6" style="border-right: 1px solid lightgrey">
            <div
              class="pa-2 ma-2"
              :style="{ backgroundColor: HRDC_CARD_COLOR }"
              outlined
            >
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  disabled
                  label="Claim Amount"
                  v-model="formattedPrice"
                  :error-messages="errorField.totalPrice"
                ></v-text-field>
              </v-col>
            </div>

            <div
              class="pa-2 ma-2"
              :style="{ backgroundColor: HRDC_CARD_COLOR }"
              outlined
            >
              <!--Refund Remarks-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-textarea
                  v-model="formData.refundRemarks"
                  filled
                  dense
                  no-resize
                  label="Refund Remarks"
                ></v-textarea>
              </v-col>
            </div>
          </v-col>

          <v-col cols="6">
            <div
              class="pa-2 ma-2"
              :style="{ backgroundColor: HRDC_CARD_COLOR }"
              outlined
            >
              <!--Screenshot Refund Bank Account Details-->
              <v-col class="d-flex pb-0" cols="12" lg="12" sm="12" md="12">
                <div class="w-100">
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    disabled
                    label="Screenshot Refund Bank Account Details"
                    v-model="formData.ssRefundBankAccDetails"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>

              <!--HRDC Invoice-->
              <v-row class="pl-3">
                <v-col class="d-flex" cols="12" lg="8" sm="8" md="8">
                  <div class="w-100">
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      label="HRDC Invoice"
                      disabled
                      v-model="formData.manualInvoice"
                      :error-messages="errorField.manualInvoice"
                      :truncate-length="1000"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col cols="12" lg="4" sm="4" md="4" class="pl-0">
                  <v-text-field
                    dense
                    filled
                    v-model="formData.manualInvoiceToHrdc"
                    :error-messages="errorField.manualInvoiceToHrdc"
                    label="HRDC Invoice No."
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>

              <!--Client Invoice-->
              <v-row class="pl-3">
                <v-col class="d-flex" cols="12" lg="8" sm="8" md="8">
                  <div
                    v-cloak
                    @drop.prevent="
                      addDropMultipleFile(
                        $event,
                        'autoGeneratedClientInvoice',
                        'autoGeneratedClientInvoiceNo',
                        'pdf.*'
                      )
                    "
                    @dragover.prevent
                    class="w-100"
                  >
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      multiple
                      label="Client Invoice"
                      accept=".pdf"
                      @change="
                        scanPdf(
                          $event,
                          'autoGeneratedClientInvoice',
                          'autoGeneratedClientInvoiceNo'
                        )
                      "
                      v-model="formData.autoGeneratedClientInvoice"
                      :error-messages="errorField.autoGeneratedClientInvoice"
                      :truncate-length="1000"
                      disabled
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col cols="12" lg="4" sm="4" md="4" class="pl-0">
                  <v-text-field
                    dense
                    filled
                    v-model="formData.autoGeneratedClientInvoiceNo"
                    :error-messages="errorField.autoGeneratedClientInvoiceNo"
                    label="Invoice No."
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>

              <!--Credit Note-->
              <v-row class="pl-3" cols="12">
                <v-col class="d-flex" lg="8" sm="8" md="8">
                  <div
                    v-cloak
                    @drop.prevent="
                      addDropMultipleFile(
                        $event,
                        'creditNote',
                        'creditNoteNo',
                        'pdf.*'
                      )
                    "
                    @dragover.prevent
                    class="w-100"
                  >
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      multiple
                      label="Credit Note"
                      @change="scanPdf($event, 'creditNote', 'creditNoteNo')"
                      v-model="formData.creditNote"
                      :error-messages="errorField.creditNote"
                      :truncate-length="1000"
                      disabled
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col lg="4" sm="4" md="4" class="pl-0">
                  <v-text-field
                    dense
                    filled
                    v-model="formData.creditNoteNo"
                    :error-messages="errorField.creditNoteNo"
                    label="CN No."
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>

              <!--Payment Advice From HRDC-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <div
                  v-cloak
                  @drop.prevent="
                    addDropFile(
                      $event,
                      'paymentAdviceFromHrdc',
                      null,
                      'image/*'
                    )
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    @change="checkAttachment($event, 'paymentAdviceFromHrdc')"
                    accept="image/*"
                    label="Payment Advice From HRDC"
                    v-model="formData.paymentAdviceFromHrdc"
                    :error-messages="errorField.paymentAdviceFromHrdc"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>

              <!--Payment Advice From Client (Stripe/iPay/...)-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <div
                  v-cloak
                  @drop.prevent="
                    addDropFile($event, 'paymentAdviceFromClient', null, null)
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    @change="checkAttachment($event, 'paymentAdviceFromClient')"
                    label="Payment Advice From Client"
                    v-model="formData.paymentAdviceFromClient"
                    :error-messages="errorField.paymentAdviceFromClient"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>

              <!--Payment Advice Type-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-select
                  :items="paymentAdviceItems"
                  v-model="formData.paymentAdviceType"
                  filled
                  dense
                  label="Payment Advice Type"
                ></v-select>
              </v-col>

              <!--Other Attachments-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <div
                  v-cloak
                  @drop.prevent="
                    addDropFile($event, 'refundOtherAttachments', null, null)
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    multiple
                    small-chips
                    @change="checkAttachment($event, 'refundOtherAttachments')"
                    label="Other Attachments (etc: Authorization Letter...)"
                    v-model="formData.refundOtherAttachments"
                    :error-messages="errorField.refundOtherAttachments"
                    :truncate-length="1000"
                    persistent-hint
                  ></v-file-input>
                </div>
              </v-col>
            </div>
          </v-col>
        </v-row>

        <!-- Refund Email Date -->
        <v-col cols="12" sm="12" md="6" v-show="false">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formData.refundEmailSubmissionDate"
                label="Refund Email Date (YYYY-MM-DD)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.refundEmailSubmissionDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    formattedPrice() {
      return `RM ${parseFloat(this.formData.totalPrice).toLocaleString()}`;
    },
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiGetRefundAttachment: new Api(),
    apiScanPdfText: new Api(),
    apiSubmit: new Api(),
    apiDownloadOrPreviewAttachment: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    alertDanger: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      totalPrice: null,
      manualInvoiceToHrdc: null,
      refundEmailSubmissionDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      autoGeneratedClientInvoice: [],
      autoGeneratedClientInvoiceNo: null,
      paymentAdviceFromHrdc: null,
      paymentAdviceFromClient: null,
      paymentAdviceType: "Stripe",
      creditNote: [],
      creditNoteNo: null,
      manualInvoice: {
        name: "HRDC Invoice",
      },
      ssRefundBankAccDetails: {
        name: "Screenshot of Refund Bank Account Details",
      },
      refundRemarks: null,
      refundOtherAttachments: [],
      paymentReceivedDate: null,
      oldCompanyName: null,
      oldSsmNo: null,
      bankUuid: null,
      oldBankAccount: null,
      oriCompanyName: null,
      oldBankAccountHolderName: null,
    },
    errorField: {
      applicationNo: null,
      totalPrice: null,
      refundEmailSubmissionDate: null,
      autoGeneratedClientInvoice: null,
      autoGeneratedClientInvoiceNo: null,
      paymentAdviceFromHrdc: null,
      paymentAdviceFromClient: null,
      paymentAdviceType: null,
      creditNote: null,
      creditNoteNo: null,
      refundOtherAttachments: null,
    },
    loadingDialog: false,
    refundEmailSubmissionSubmitDialog: false,
    editMode: false,
    btnDisabled: false,
    paymentAdviceItems: null,
  }),
  async created() {
    this.showLoadingDialog();
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign payment advice options.
    this.paymentAdviceItems = this.paymentAdviceOpts;

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_submission/create-refund-email-submission`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_submission/scan-pdf`,
        method: "post",
      },
      apiGetRefundAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment-array`,
        method: "post",
      },
      apiDownloadOrPreviewAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/azure-download-or-preview-application-attachment`,
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc_einvoicing.data.stageId];
    this.rolesAssignation(this.auth.roles);
    if (!this.isTaxpodAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    if (currentStageId >= this.hrdcData.stageSlug.refundEmailSubmission) {
      this.alertError.push("You already submitted this form");
      this.editMode = true;
    }

    if (currentStageId == this.hrdcData.stageSlug.creditNoteDocumented) {
      if (currentStageId < this.hrdcData.stageSlug.creditNoteDocumented) {
        this.alertError.push("Please complete previous step");
        this.btnDisabled = true;
      }
    } else if (currentStageId == this.hrdcData.stageSlug.creditNoteGenerated) {
      if (currentStageId < this.hrdcData.stageSlug.creditNoteGenerated) {
        this.alertError.push("Please complete previous step");
        this.btnDisabled = true;
      }
    }

    // If edit form to resend email submission.
    this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
      try {
        const { data, company } = response;

        // Check if pricing is unsual, display warning.
        if (!this.getBasePricing(data.totalPrice)) {
          this.alertDanger.push("Detected Unusual Price, Please Take Note");
        }

        const attachments = {};
        let otherRefundAttnCount = 0;
        const stagePriorities = [
          this.hrdcData.stagePriority.creditNoteGenerated,
          this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice,
          this.hrdcData.stagePriority.paymentAdviceFromHrdc,
          this.hrdcData.stagePriority.paymentAdviceFromClient,
          this.hrdcData.stagePriority.refundEmailOtherAttachments,
        ];
        this.formData.totalPrice = data.totalPrice;
        this.formData.manualInvoiceToHrdc = data.manualInvoiceToHrdc;
        this.formData.autoGeneratedClientInvoiceNo =
          data.autoGeneratedClientInvoiceNo;
        this.formData.creditNoteNo = data.creditNoteNo;
        this.formData.paymentAdviceType = data.paymentAdviceFromClientType;
        this.formData.oldCompanyName = this.capitalizeFirstLetter(
          company.oldCompanyName
        );
        this.formData.oldSsmNo = company.oldSsmNo;
        this.formData.bankUuid = data.bankUuid;
        this.formData.oldBankAccount = data.oldBankAccount;
        this.formData.refundRemarks = data.refundRemarks;

        if (this.editMode) {
          this.formData.refundEmailSubmissionDate = moment(
            data.ApplicationLog.find(
              (obj) =>
                obj.stageId == this.hrdcData.stagePriority.refundEmailSubmission
            ).startDate
          ).format("YYYY-MM-DD");
        } else {
          let paymentReceivedDate = data.ApplicationLog.find(
            (item) =>
              item.stageId ==
              this.hrdcData.stagePriority.paymentReceivedFromHrdc
          );

          if (paymentReceivedDate) {
            this.formData.paymentReceivedDate = moment(
              paymentReceivedDate.startDate,
              "YYYY-MM-DD HH:mm:ssZ"
            ).format("D MMMM YYYY");
          }

          // Assign Original company name filled by applicant.
          this.formData.oriCompanyName = data.oriCompanyName;

          this.formData.oldBankAccountHolderName =
            company.oldBankAccountHolderName;

          let clientInvoiceFile = null;
          let clientInvoice = data.ApplicationAttachment.filter(
            (item) =>
              item.stageId ==
              this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice
          );

          if (clientInvoice.length > 0) {
            clientInvoiceFile = clientInvoice;
          }

          // Construct params to retrieve client invoice.
          attachments[this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice] =
            {
              fileUrl: clientInvoiceFile.map((item) => item.fileUrl),
              fileName: "Client Invoice",
            };
        }

        for (const stageId of stagePriorities) {
          let filesArr = [];
          const attachment = data.ApplicationAttachment.filter(
            (item) => item.stageId == stageId
          );

          if (attachment.length > 0) {
            for (const atch of attachment) {
              // Cater for other refund attachments.
              if (
                stageId ==
                this.hrdcData.stagePriority.refundEmailOtherAttachments
              ) {
                if (!attachments[stageId]) {
                  attachments[stageId] = {};
                }

                attachments[stageId][otherRefundAttnCount] = {
                  fileUrl: atch.fileUrl,
                  fileName: atch.oriFileName,
                };
                otherRefundAttnCount++;
              } else {
                filesArr.push(atch.fileUrl);

                attachments[stageId] = {
                  fileName: attachment.oriFileName,
                };
              }
            }

            attachments[stageId].fileUrl = filesArr;
          }
        }

        for (const stageId of stagePriorities) {
          let filesArr = [];
          const attachment = data.ApplicationAttachment.filter(
            (item) => item.stageId == stageId
          );

          if (attachment.length > 0) {
            for (const atch of attachment) {
              // Cater for other refund attachments.
              if (
                stageId ==
                this.hrdcData.stagePriority.refundEmailOtherAttachments
              ) {
                if (!attachments[stageId]) {
                  attachments[stageId] = {};
                }

                attachments[stageId][otherRefundAttnCount] = {
                  fileUrl: atch.fileUrl,
                  fileName: atch.oriFileName,
                };
                otherRefundAttnCount++;
              } else {
                filesArr.push(atch.fileUrl);

                attachments[stageId] = {
                  fileName: attachment.oriFileName,
                };
              }
            }

            attachments[stageId].fileUrl = filesArr;
          }
        }

        // Get refund email attachment.
        this.apiGetRefundAttachment.setParams({
          applicationUuid: this.$route.query._ausk,
          attachments,
        });

        this.apiGetRefundAttachment.setCallbackCompleted((response) => {
          try {
            const { status, attn } = response;
            if (status) {
              for (const item of attn) {
                // Create a Blob object from the data
                const blob = new Blob([new Uint8Array(item.dataArray)]);

                let fileName = null;
                let file = null;

                // Cater for other refund email attachment.
                if (item.other == true) {
                  switch (item.type) {
                    case "refundEmailOtherAttachments":
                      file = new File([blob], item.fileName, {
                        type: `application/${item.extension}`,
                      });

                      this.formData.refundOtherAttachments.push(file);
                      break;
                  }
                  continue;
                }

                switch (Number(item.type)) {
                  case this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice:
                    fileName = "Client Invoice";
                    // Create the File object
                    file = new File([blob], fileName, {
                      type: `application/${item.extension}`,
                    });

                    this.formData.autoGeneratedClientInvoice.push(file);
                    break;

                  case this.hrdcData.stagePriority.creditNote:
                  case this.hrdcData.stagePriority.creditNoteGenerated:
                    fileName = "Credit Note";
                    // Create the File object
                    file = new File([blob], fileName, {
                      type: `application/${item.extension}`,
                    });

                    this.formData.creditNote.push(file);
                    break;

                  case this.hrdcData.stagePriority.paymentAdviceFromHrdc:
                    fileName = "Payment Advice From HRDC";
                    // Create the File object
                    file = new File([blob], fileName, {
                      type: `application/${item.extension}`,
                    });

                    this.formData.paymentAdviceFromHrdc = file;
                    break;

                  case this.hrdcData.stagePriority.paymentAdviceFromClient:
                    fileName = "Payment Advice From Client";
                    // Create the File object
                    file = new File([blob], fileName, {
                      type: `application/${item.extension}`,
                    });

                    this.formData.paymentAdviceFromClient = file;
                    break;
                }
              }
            }
          } catch (err) {
            console.log(err);
          }
        });

        this.apiGetRefundAttachment.fetch();
      } catch (err) {
        console.log(err);
      } finally {
        this.hideLoadingDialog();
      }
    });
    this.apiGetApplicationSingleDetail.fetch();
  },
  methods: {
    clearErrorMsg(field = null) {
      if (!field) {
        for (let error in this.errorField) {
          this.errorField[error] = null;
        }
      } else {
        this.errorField[field] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    modalRefundEmailSubmissionSubmit() {
      this.refundEmailSubmissionSubmitDialog =
        !this.refundEmailSubmissionSubmitDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcEinvoicingApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_einvoicing.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    addDropFile(e, key, fileType, matchExtType) {
      let file = e.dataTransfer.files[0];

      if (matchExtType) {
        if (!file.type.match(matchExtType)) {
          switch (matchExtType) {
            case "pdf.*":
              this.errorField[key] = "Please select pdf file format";
              break;
            case "image/*":
              this.errorField[key] = "Please select only image";
              break;
          }
          return;
        }
      }

      this.formData[key] = file;
      if (fileType) {
        this.scanPdf(e, key, fileType);
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    addDropMultipleFile(e, key, fileType, matchExtType) {
      let files = e.dataTransfer.files;

      for (const file of files) {
        if (matchExtType) {
          if (!file.type.match(matchExtType)) {
            switch (matchExtType) {
              case "pdf.*":
                this.errorField[key] = "Please select pdf file format";
                break;
              case "image/*":
                this.errorField[key] = "Please select only image";
                break;
            }
            return;
          }
        }

        this.formData[key].push(file);

        if (fileType) {
          this.scanPdf(e, key, fileType);
        }
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    scanPdf($event, fileType, displayFileInvoiceNo) {
      this.clearErrorMsg(fileType);
      this.clearErrorMsg(displayFileInvoiceNo);
      if ($event) {
        this.showLoadingDialog();
        const fd = new FormData();

        let counter = 0;
        for (const file of this.formData[fileType]) {
          fd.append(`${fileType}_${counter}`, file);
          counter++;
        }

        fd.append("fileType", fileType);
        fd.append("counter", counter);

        this.apiScanPdfText.setParams(fd);
        this.apiScanPdfText.setCallbackCompleted((response) => {
          try {
            const { data } = response;
            if (response.status) {
              this.formData[displayFileInvoiceNo] = data;
            } else {
              this.formData[displayFileInvoiceNo] = null;
              this.$store.dispatch("showMessage", {
                message: "Unable to scan text!",
                messageType: "error",
                timeout: 2000,
              });
            }
            this.hideLoadingDialog();
          } catch (err) {
            console.log(err);
          }
        });
        this.apiScanPdfText.fetch();
      } else {
        // Empty the invoice no. if remove attachment.
        this.formData[displayFileInvoiceNo] = null;
      }
    },
    isValidURL(str) {
      try {
        new URL(str);
        return true;
      } catch (error) {
        return false;
      }
    },
    previewOrDownloadAttachment(fileUrl) {
      this.showLoadingDialog();

      // Check if a valid url, directly open it.
      const isValidUrl = this.isValidURL(fileUrl);
      if (isValidUrl) {
        window.open(fileUrl, "_blank");
      } else {
        this.$axios
          .get(this.apiDownloadOrPreviewAttachment.url, {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              filePath: fileUrl,
              isImage: false,
            },
          })
          .then((response) => {
            const blobUrl = URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );

            window.open(blobUrl, "_blank");
          })
          .catch((error) => {
            if ((error.response.status == 404) | true) {
              this.$store.dispatch("showMessage", {
                message:
                  "Failed to Preview/Download attachment, kindly contact administrator",
                messageType: "error",
                timeout: 2000,
              });
            }
          });
      }

      this.hideLoadingDialog();
    },
    capitalizeFirstLetter(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    checkAttachment(e, field) {
      if (e) {
        this.clearErrorMsg(field);
      }
    },
    getFileExt(fileType) {
      if (fileType) {
        // Get the file extension type.
        const fileSplit = fileType.split("/");
        return fileSplit[1];
      }

      return fileType;
    },
    async submit() {
      // const changeFileExtensions = ["paymentAdviceFromClient"];

      // for (const file of changeFileExtensions) {
      //   if (this.formData[file]) {
      //     let oriFileExt = await this.getOriginalAttachmentFileExtension(
      //       this.formData[file]
      //     );

      //     this.formData[file] = new File(
      //       [this.formData[file]],
      //       this.formData[file].name,
      //       {
      //         type: oriFileExt,
      //       }
      //     );
      //   }
      // }

      this.alertError = [];
      this.showLoadingDialog();

      for (const file of this.REFUND_EVENT_ATTACHMENTS) {
        if (!this.formData[file]) {
          this.alertError.push("Please fill in all the fields!");
          this.hideLoadingDialog();
          return false;
        }
      }

      // Generate Form Data to handle multipart/form-data
      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("totalPrice", this.formData.totalPrice);

      // Loop client invoice.
      let clientInvoiceCounter = 0;
      for (const clientInvoice of this.formData.autoGeneratedClientInvoice) {
        fd.append(
          `autoGeneratedClientInvoice_${clientInvoiceCounter}`,
          clientInvoice
        );
        clientInvoiceCounter++;
      }
      fd.append("clientInvoiceCounter", clientInvoiceCounter);

      fd.append(
        "autoGeneratedClientInvoice",
        this.formData.autoGeneratedClientInvoice
      );

      fd.append(
        "autoGeneratedClientInvoiceNo",
        this.formData.autoGeneratedClientInvoiceNo
      );

      let cnCounter = 0;
      for (const cn of this.formData.creditNote) {
        fd.append(`creditNote_${cnCounter}`, cn);
        cnCounter++;
      }

      fd.append("cnCounter", cnCounter);
      fd.append("creditNoteNo", this.formData.creditNoteNo);
      fd.append(
        "refundEmailSubmissionDate",
        this.formData.refundEmailSubmissionDate
      );
      fd.append(
        "paymentAdviceFromClient",
        this.formData.paymentAdviceFromClient
      );
      fd.append("paymentAdviceFromHrdc", this.formData.paymentAdviceFromHrdc);
      fd.append("paymentAdviceType", this.formData.paymentAdviceType);
      fd.append("referById", this.auth.uuid);
      fd.append("editMode", this.editMode);
      fd.append("refundRemarks", this.formData.refundRemarks);

      // Assign multiple attachments to Form Data.
      for (let i = 0; i < this.formData.refundOtherAttachments.length; i++) {
        fd.append(
          `refundOtherAttachments_${i}`,
          this.formData.refundOtherAttachments[i]
        );
      }

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.hideLoadingDialog();
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcEinvoicingApplication",
              query: {
                viewId: this.$store.state.hrdc_einvoicing.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>

<style scroped>
.v-messages__message {
  color: red;
}
</style>
